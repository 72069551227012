import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Theme from "../Theme";
import UnAuthResult from "../Components/UnAuthResult";
import * as Cart from "../Contexts/CartContext";
import { Context } from "../AppContext";
import { errorHandler } from "../errors";
import { navigate, Link } from "gatsby";
import {
  Block,
  FieldRow,
  Hint,
  Label,
  Value,
} from "../Components/Order.Widget";
const qs = require("query-string");

function PeriodPage(props) {
  const { profile } = props;
  const { payment_status, period_id } = qs.parse(props.location.search);
  const [period, setPeriod] = useState();
  const [orders, setOrders] = useState([]);
  const app = useContext(Context);

  useEffect(() => {
    async function getOrder() {
      let resp = await Cart.Actions.fetchOrders({
        period_order: period_id,
        no_page: true,
      });
      setOrders(resp);
      return resp;
    }
    if (payment_status === "success" && period_id && profile) {
      let timer = null;
      let count = 0;
      timer = setInterval(async () => {
        count++;
        let result = await getOrder();
        if (result?.length > 0 || count > 3) {
          clearInterval(timer);
        }
      }, 2000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [app.actions, Cart.Actions, payment_status, period_id, profile]);

  useEffect(() => {
    if (period_id && profile) {
      (async () => {
        app.actions.setLoading(true);

        try {
          let resp = await app.actions.getPeriod(period_id);
          setPeriod(resp);
        } catch (err) {
          errorHandler(err, "取得委託單錯誤");
        }
        app.actions.setLoading(false);
      })();
    }
  }, [app.actions, payment_status, period_id, profile]);

  const { Status, Message } = useMemo(() => {
    try {
      let obj = JSON.parse(period?.payment_transaction_detail);
      return { ...obj };
    } catch (err) {
      return {};
    }
  }, [period]);

  if (!profile) {
    return <UnAuthResult />;
  }

  return (
    <Wrapper>
      {!profile ? (
        <UnAuthResult />
      ) : (
        <div className="center-content">
          <OrderInfoWrapper>
            <div className="header">委託單資訊</div>
            <div className="content">
              <Block color={Theme.colors.main}>
                <div className="block-header">委託單資訊</div>
                <FieldRow>
                  <Label>委託單成立狀態：</Label>
                  <Value
                    style={{
                      color:
                        Status === "SUCCESS"
                          ? Theme.colors.brown
                          : Theme.colors.red,
                    }}
                  >
                    {period
                      ? Status === "SUCCESS"
                        ? "成功"
                        : "錯誤"
                      : payment_status === "success"
                      ? "成功"
                      : "錯誤"}
                  </Value>
                </FieldRow>
                {Status !== "SUCCESS" && (
                  <FieldRow>
                    <Label>錯誤訊息：</Label>
                    <Value style={{ color: Theme.colors.red }}>{Message}</Value>
                  </FieldRow>
                )}
                <FieldRow>
                  <Label>期數：</Label>
                  <Value>
                    {period?.times} / {period?.auth_times}
                  </Value>
                </FieldRow>
                <FieldRow>
                  <Label>每期金額：</Label>
                  <Value>{period?.period_amount}</Value>
                </FieldRow>
                <FieldRow>
                  <Label>付款方式：</Label>
                  <Value>
                    {period?.payment_method === "CREDIT" ? "信用卡" : ""}
                  </Value>
                </FieldRow>
                <FieldRow>
                  <Label>訂單詳細：</Label>
                  <Value>
                    {orders?.length > 0 && Status === "SUCCESS" ? (
                      <Link to={`/order?id=${orders[0].id}`}>
                        {orders[0].display_id}
                      </Link>
                    ) : (
                      <div style={{ marginRight: 10 }}>無訂單資訊</div>
                    )}
                  </Value>
                </FieldRow>
                <FieldRow>
                  <Label>
                    若沒有訂單資訊，可能在處理中，可重新整理此頁，或前往訂閱訂單列表查看
                  </Label>
                  <Ant.Button
                    type="primary"
                    onClick={() => navigate(`/periods`)}
                  >
                    訂閱訂單列表
                  </Ant.Button>
                </FieldRow>
                <FieldRow>
                  <Label>若委託單成立失敗，請前往購物車再次下單</Label>
                  <Ant.Button
                    type="primary"
                    onClick={() => navigate(`/cart`)}
                    style={{ marginRight: 10 }}
                  >
                    購物車
                  </Ant.Button>
                </FieldRow>
              </Block>
            </div>
          </OrderInfoWrapper>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;

  & > .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;
    padding: ${Theme.rwdPadding.outer};
    & > .cart-content-grid {
      padding: ${Theme.rwdPadding.large};
    }

    & > .amount-section {
      padding-top: ${Theme.rwdPadding.outer};
      padding-bottom: ${Theme.rwdPadding.outer};
    }
  }
`;

const OrderInfoWrapper = styled.div`
  border: 1px solid #ccc;
  & > .header {
    display: flex;
    justify-content: center;
    color: ${Theme.colors.textReverse};
    background-color: ${Theme.colors.darkgrey};
    padding: 9px;
  }
  & > .content {
    display: flex;
    padding: ${Theme.rwdPadding.large};
    @media only screen and (max-width: ${Theme.breakpoints.lg}px) {
      flex-direction: column;
      padding: ${Theme.rwdPadding.inner};
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(PeriodPage);
