import React from "react";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import * as Ant from "antd";
import { Context } from "../AppContext";
import * as Theme from "../Theme";

function UnAuthResult(props) {
  const app = React.useContext(Context);
  const { profile } = app.state;

  if (!profile) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: Theme.colors.eggShell,
          padding: 20,
          height: "70vh",
        }}
      >
        <figure
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="../../images/plz-login.svg"
            width="280px"
            style={{ maxWidth: 650, marginBottom: 30 }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginBottom: 5,
                color: Theme.colors.text,
              }}
            >
              您尚未登入會員，
            </p>
            <p
              style={{
                fontSize: 18,
                marginBottom: 5,
                color: Theme.colors.text,
              }}
            >
              請登入感官會員或註冊新帳號。
            </p>
          </div>
          <div
            onClick={() => {
              app.actions.setLoginModal(true);
            }}
            style={{
              cursor: "pointer",
              marginTop: 50,
              padding: "10px 50px",
              border: `1px solid ${Theme.colors.unSelected}`,
              borderRadius: 3,
              color: Theme.colors.text,
            }}
          >
            前往登入 Login
          </div>
        </figure>
      </div>
    );
  }
  return null;
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  null
)(UnAuthResult);
