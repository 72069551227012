import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import * as Theme from "../Theme";
import * as Icon from "./Icon";

const Block = styled.div`
  & > .block-header {
    color: ${Theme.colors.darkgrey};
    font-size: 18px;
    border-bottom: 1px solid ${Theme.colors.border_grey};
    padding-bottom: 12px;
    margin-bottom: 20px;
  }
`;

const SectionHeader = styled.p`
  border: 1px solid #ccc;
  background-color: ${Theme.colors.darkgrey};
  padding: 5px 20px;
  text-align: center;
  color: ${Theme.colors.textReverse};
  ${props => (props.first ? "margin-top: 0px;" : "")}
`;

const SectionWrapper = styled.div`
  padding: 24px ${Theme.rwdPadding.large};
  ${props => props.css || ""}
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  ${props => props.css}
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.css || ""}
`;

const Value = styled.div`
  /* flex-basis: 220px; */
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}
  color: ${props => props.color || "default"};
`;

const Label = styled.label`
  margin-right: 10px;
  /* flex-basis: 100px;
   */
  flex-shrink: 0;
  color: ${Theme.colors.text};
  ${props => props.css || ""}
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TextInput = styled(Ant.Input)`
   padding: 8px;
  border: none;
  background-color: ${Theme.colors.sub};
  margin: 6px 0px;
  flex-basis: 450px;
  flex-shrink: 0;
  ${props => (props.type === "short" ? "flex-basis: 140px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  @media screen and (max-width: ${Theme.breakpoints.lg}px) {
    flex-basis: ${props =>
      props.type === "long" ? "336px" : props.type === "short" ? "120px" : ""};
    flex-shrink: 1;
  }
  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    flex-basis: ${props =>
      props.type === "long" ? "260px" : props.type === "short" ? "90px" : ""};
    flex-shrink: 1;
  }


  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */

${props => props.css}
`;

const Divider = styled.div`
  width: 100%;
  border-top: solid 1px #eee;
  margin: 10px 0;
`;

// green hint
const Hint = styled.span`
  color: ${Theme.colors.main};
  font-size: 12px;
`;

// error red hint
const ErrorHint = ({ message }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", margin: "0 10px" }}>
      <Icon.AlertCircle size={20} color={Theme.colors.red} />
      <div style={{ color: Theme.colors.red, marginLeft: 5 }}>{message}</div>
    </div>
  );
};

const Select = styled(Ant.Select)`
  margin: 5px;
  flex-basis: 150px;
`;

const SelectOption = styled(Ant.Select.Option)`
  width: 150px;
`;

export {
  SectionWrapper,
  SectionHeader,
  FieldRow,
  InputContainer,
  Value,
  Label,
  Footer,
  Divider,
  Block,
  Hint,
  ErrorHint,
  Select,
  SelectOption,
  TextInput,
};
